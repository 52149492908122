import React, { ReactElement } from 'react';
import { Github, LinkedIn, Twitter } from '../components';
import '../../node_modules/modern-normalize/modern-normalize.css';
import '../styles/index.css';
import {
  __BlogContainer,
  __BlogDate,
  __BlogTitle,
  __BodyContainer,
  __Brand,
  __Container,
  __Grid,
  __Name,
  __NavContainer,
  __NavItem,
  __NavLink,
  __NavMenu,
  __Social,
  __SocialLink,
  __Wrapper,
} from '../styles/index';

import mockBlogPost from '../mock-data/blog-post.json';
import mockMenuContent from '../mock-data/menu-content.json';
import mockName from '../mock-data/name.json';

const App = (): ReactElement => {
  return (
    <__Wrapper>
      <__Container>
        <__Grid>
          <__NavContainer>
            <__Brand>
              {mockName.map((item: string) => (
                <__Name key={item}>{item}</__Name>
              ))}
            </__Brand>
            <__NavMenu>
              {mockMenuContent.map(
                (item): ReactElement => (
                  <__NavItem isActive={item.isActive} key={item.textContent}>
                    <__NavLink href={item.url}>{item.textContent}</__NavLink>
                  </__NavItem>
                )
              )}
            </__NavMenu>
            <__Social>
              <__SocialLink href='https://www.github.com/rwschmitz' target='_blank' rel='noopener noreferrer'>
                <Github />
              </__SocialLink>
              <__SocialLink href='https://www.linkedin.com/in/rudolph-schmitz/' target='_blank' rel='noopener noreferrer'>
                <LinkedIn />
              </__SocialLink>
              <__SocialLink href='https://twitter.com/rudolphschmitz' target='_blank' rel='noopener noreferrer'>
                <Twitter />
              </__SocialLink>
            </__Social>
          </__NavContainer>
          <__BodyContainer>
            <__BlogContainer>
              <__BlogTitle>Blog Title</__BlogTitle>
              <__BlogDate>March 2nd, 2020</__BlogDate>
            </__BlogContainer>
            {mockBlogPost.map((item: string) => (
              <p key={item}>{item}</p>
            ))}
          </__BodyContainer>
        </__Grid>
      </__Container>
    </__Wrapper>
  );
};

export default App;
