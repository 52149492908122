import styled from 'styled-components';

const white = '#eee';
const primary = '#1e1e1e';
const secondary = '#9ba2ff';

const small = '@media (min-width: 600px)';
const medium = '@media (min-width: 960px)';
const large = '@media (min-width: 1600px)';

interface StyledProps {
  isActive?: boolean;
}

export const __Wrapper = styled.main`
  background-color: ${primary};
  color: ${white};
`;

export const __Container = styled.div`
  margin: 0 auto;
  max-width: 1600px;
`;

export const __Grid = styled.div`
  display: grid;
  grid-template-areas:
    'nav'
    'body';
  ${small} {
    grid-template-areas: 'nav body body body';
  }
`;

export const __NavContainer = styled.nav`
  background-color: ${secondary};
  grid-area: nav;
  padding-left: 2rem;
  padding-right: 2rem;
  ${small} {
    height: 100%;
  }
  ${medium} {
    width: 300px;
  }
`;

export const __NavLink = styled.a`
  display: block;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  text-decoration: none;
  ${large} {
    font-size: 1.25rem;
  }
`;

export const __NavItem = styled.li<StyledProps>`
  background-color: ${(props): string => (props.isActive ? 'black' : 'transparent')};
  border-radius: 0.25rem;
  box-shadow: ${(props): string => (props.isActive ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none')};
  opacity: ${(props): number => (props.isActive ? 0.75 : 1)};
  padding-left: 0.625rem;
  &:hover {
    background-color: black;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    ${__NavLink} {
      color: ${secondary};
    }
  }
  ${__NavLink} {
    color: ${(props): string => (props.isActive ? secondary : primary)};
  }
`;

export const __NavMenu = styled.ul`
  list-style-type: none;
  margin-bottom: 2.5rem;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
`;

export const __Name = styled.span`
  display: block;
  padding-left: 0.625rem;
  ${small} {
    font-size: 2rem;
  }
  ${medium} {
    &:last-of-type {
      text-align: right;
    }
  }
`;

export const __Brand = styled.h1`
  color: ${primary};
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
  ${small} {
    margin-bottom: 4.5rem;
    margin-top: 2rem;
    ${__Name} {
      &:first-child {
        border-bottom: 2px solid ${primary};
        padding-bottom: 0.5rem;
      }
    }
  }
`;

export const __BodyContainer = styled.section`
  grid-area: body;
  margin: 0 auto;
  max-width: 1012px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2.5rem;
  p {
    line-height: 2;
    margin-bottom: 2rem;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-top: 0.5rem;
  }
  ${small} {
    padding-top: 2rem;
  }
  ${large} {
    padding-left: 0;
    padding-right: 0;
    p {
      font-size: 1.125rem;
    }
  }
`;

export const __BlogContainer = styled.div`
  margin-bottom: 2.5rem;
  ${small} {
    margin-bottom: 4.5rem;
  }
`;

export const __BlogTitle = styled.h2`
  border-bottom: 2px solid ${secondary};
  display: inline-block;
  font-size: 2rem;
  font-weight: 400;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0;
`;

export const __BlogDate = styled.h3`
  font-size: 0.875rem;
  font-weight: 400;
`;

export const __Social = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 0.75rem;
`;

export const __SocialLink = styled.a`
  color: black;
  height: 24px;
  margin-right: 2rem;
  opacity: 0.75;
  width: 24px;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    opacity: 1;
  }
`;
